import {GridRowClassNameParams, GridRowParams} from '@mui/x-data-grid-pro';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import DataGridProTable from '~/components/generics/DataGridProTable';
import CODialog from '~/components/subsite/change/changeOrders/CODialog';
import COTableCols from '~/components/subsite/change/changeOrders/COTableCols';
import LoadingComponent from '~/components/visualizations/LoadingComponent';
import {useStoreActions, useStoreState} from '~/store/storeHooks';
import styles from '~/styles/DataGridPro.module.scss';

const tableCacheVersion = 1;

export default function COTable(): JSX.Element {
    const {projectId} = useParams<{projectId: string}>();
    const project = useStoreState((state) => state.subsite.project);
    const getChangeOrders = useStoreActions((actions) => actions.change.getChangeOrders);
    const changeOrders = useStoreState((state) => state.change.changeOrders);
    const [busy, setBusy] = useState<any>(true);

    const navigate = useNavigate();

    const columns = COTableCols();

    useEffect(() => {
        if (projectId) {
            setBusy(true);
            (async function fetchData(): Promise<void> {
                await getChangeOrders(parseInt(projectId, 10));
            })();
            setBusy(false);
        }
        return (): void => {
            setBusy(false);
        };
    }, [getChangeOrders, projectId]);

    const handleRowClick = useCallback(
        (params: GridRowParams): void => {
            navigate(`/project/${projectId}/change?order=${params.row.id}`);
        },
        [navigate, projectId],
    );

    const getRowClassName = (params: GridRowClassNameParams) => {
        const {status, answered} = params.row;
        let cn = styles.row;
        if (status === 'Open' && !answered) {
            cn = `${cn} ${styles.actionRequired}`;
        } else if (status === 'Open' && answered) {
            cn = `${cn} ${styles.actionable}`;
        } else {
            cn = `${cn} ${styles.readOnly}`;
        }
        if (params.row.descendent) cn = `${cn} ${styles.treeBranch}`;
        return cn;
    };

    return (
        <div id="CR-table-root">
            {busy ? (
                <LoadingComponent title="Loading Change Orders" contained />
            ) : (
                <>
                    <DataGridProTable
                        columns={columns}
                        data={changeOrders}
                        optionKey="coTable"
                        exportAllColumns
                        csvFileName={project?.code ?? 'Change Orders'}
                        cacheVersion={tableCacheVersion}
                        handleRowClick={handleRowClick}
                        getRowClassName={getRowClassName}
                        excelOptions={{tableName: 'pcn', projectId: parseInt(projectId, 10), projectCode: project?.code}}
                    />
                    <CODialog />
                </>
            )}
        </div>
    );
}

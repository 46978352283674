import {action, Action, thunk, Thunk} from 'easy-peasy';
import {axios} from '~/api';
import {AxiosTryCatchBlock} from '~/helpers';
import {ChangeOrder} from '~/types';

const defaultTimeout = 30000;

export interface ChangeManagementModel {
    changeOrders: ChangeOrder[];
    setChangeOrders: Action<ChangeManagementModel, ChangeOrder[]>;
    getChangeOrders: Thunk<ChangeManagementModel, number>;
    updateChangeOrder: Thunk<ChangeManagementModel, any>;
    getPcnLogExcelExport: Thunk<ChangeManagementModel, {projectId: number; projectCode: string}>;
}

const changeManagementModel: ChangeManagementModel = {
    changeOrders: [],

    setChangeOrders: action((draftState, changeOrders) => {
        draftState.changeOrders = changeOrders;
    }),

    getChangeOrders: thunk(async (actions, projectId) => {
        try {
            const {data} = await axios.get(`/ChangeOrders?projectId=${projectId}`, {
                timeout: defaultTimeout,
            });
            actions.setChangeOrders(data);
            return data;
        } catch(e: any) {
         return {result: 'error', message: `${e?.name  } ${  e?.message}`}
        };
    }),

    updateChangeOrder: thunk(async (actions, payload) => {
        try {
            const {data} = await axios.post('/UpdateChangeOrder', payload, {
                timeout: defaultTimeout,
            });
            actions.setChangeOrders(data);
            return {result: 'success', message: 'Successfully updated change order', data};
        }
        catch(e: any) {
            return {result: 'error', message: `${e?.name  } ${  e?.message}`}
        };
    }),
    getPcnLogExcelExport: thunk((actions, {projectId, projectCode}) =>
        AxiosTryCatchBlock(async () => {
            const success = await axios({
                url: `/DownloadExcelPcnLog?id=${projectId}`,
                method: 'GET',
                responseType: 'blob',
            });

            if (success.status === 200) {
                const blob = new Blob([success.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                link.download = `${projectCode}_PCN-Log.xlsx`;
                link.click();
                window.URL.revokeObjectURL(url);
            }
        }),
    ),
};

export default changeManagementModel;
